import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Import ClerkProvider from '@clerk/clerk-react'
import { ClerkProvider } from '@clerk/clerk-react';

// Retrieve the Clerk publishable key from environment variables
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!clerkPubKey) {
    throw new Error('Missing Clerk publishable key');
}

// Define the appearance object for Clerk components
const appearance = {
    variables: {
        colorPrimary: '#007bff', // Your primary theme color
        // Add other variables as needed
    },
    elements: {
        // Customize specific elements
        card: {
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
        },
        // You can customize other elements here
    },
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ClerkProvider publishableKey={clerkPubKey} appearance={appearance}>
            <App />
        </ClerkProvider>
    </React.StrictMode>
);

// Optional: Measure performance in your app
reportWebVitals();
