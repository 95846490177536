import React from 'react';
import { SignInButton, SignUpButton } from '@clerk/clerk-react';
import './LandingPage.css';

function LandingPage() {
    return (
        <div className="landing-page">
            {/* Top Navigation Bar */}
            <header className="navbar">
                <div className="navbar-left">
                    <h1 className="site-title">SportSnipeAI</h1>
                </div>
                <div className="navbar-right">
                    <SignInButton mode="modal">
                        <button className="nav-button log-in">Log In</button>
                    </SignInButton>
                    <SignUpButton mode="modal">
                        <button className="nav-button get-started">Get Started</button>
                    </SignUpButton>
                </div>
            </header>

            {/* Hero Section */}
            <section className="hero">
                <div className="container">
                    <h1 className="hero-title">Chat With Barry Snipes to Find The Edge</h1>
                    <p className="hero-subtitle">Your personal sports handicapper for finding the seasoned angles in sports betting.</p>
                </div>
            </section>

            {/* Features Section */}
            <section className="features">
                <div className="container">
                    <h2>Discover the Features</h2>
                    <div className="feature-list">
                        <div className="feature-item">
                            <h3>Advanced Analytics</h3>
                            <p>Leverage cutting-edge AI to analyze sports data and uncover hidden insights.</p>
                        </div>
                        <div className="feature-item">
                            <h3>Real-Time Updates</h3>
                            <p>Stay ahead with live updates and predictions for ongoing games.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer Section */}
            <footer className="landing-footer">
                <div className="container">
                    <p>&copy; {new Date().getFullYear()} SportSnipeAI LLC. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
}

export default LandingPage;
