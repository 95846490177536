// src/App.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { FaSpinner } from 'react-icons/fa';
import './App.css';

// Clerk imports
import {
    SignedIn,
    SignedOut,
    SignInButton,
    SignUpButton,
    UserButton,
    useUser,
    useAuth,
} from '@clerk/clerk-react';

import LandingPage from './LandingPage'; // Import the LandingPage component

function App() {
    const [userInput, setUserInput] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [threadId, setThreadId] = useState(null);
    const chatBoxRef = useRef(null);
    const textareaRef = useRef(null);
    const lastMessageRef = useRef(null);

    const [spinnerMessage, setSpinnerMessage] = useState(
        'Digging into the details... results are coming!'
    );

    // Clerk hooks
    const { isLoaded, isSignedIn, user } = useUser();
    const { getToken } = useAuth();

    // User approval logic
    const isApproved = user?.publicMetadata?.approved;

    // Function to send user input to the backend
    const handleSend = async () => {
        if (userInput.trim()) {
            const newHistory = [...chatHistory, { user: userInput }];
            setChatHistory(newHistory);
            setIsProcessing(true);
            setUserInput('');

            try {
                // Get the Clerk authentication token
                const authToken = await getToken();

                // Send user input to the backend, including the auth token
                const response = await axios.post(
                    'https://backend-api-dot-barrysnipes.uc.r.appspot.com/interact',
                    {
                        input: userInput,
                        thread_id: threadId,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    }
                );

                const botResponse = response.data.response;
                setThreadId(response.data.thread_id);

                // Add bot response to chat history
                setChatHistory((prevHistory) => [...prevHistory, { bot: botResponse }]);
            } catch (error) {
                console.error('Error communicating with backend:', error);
                setChatHistory((prevHistory) => [
                    ...prevHistory,
                    {
                        bot: 'Stepped away from the lab for a bit. Please check back in an hour or so.',
                    },
                ]);
            } finally {
                setIsProcessing(false);
            }
        }
    };

    // Auto-scroll to the bottom of the chat when a new message is added
    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatHistory]);

    // Reset textarea height when userInput is cleared
    useEffect(() => {
        if (userInput === '' && textareaRef.current) {
            textareaRef.current.style.height = 'auto';
        }
    }, [userInput]);

    // useEffect to change the spinner message after 15 seconds
    useEffect(() => {
        let timer;
        if (isProcessing) {
            timer = setTimeout(() => {
                setSpinnerMessage('Still digging in. Give me a few moments to lock in.');
            }, 15000);
        } else {
            setSpinnerMessage('Digging into the details... results are coming!');
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isProcessing]);

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <div className="main-container">
            {isSignedIn ? (
                <>
                    <div className="left-panel">
                        {/* UserButton placed here for desktop */}
                        <SignedIn>
                            <div className="desktop-user-button">
                                <UserButton />
                            </div>
                        </SignedIn>
                    </div>
                    <div className="chat-container">
                        {/* Header */}
                        <header>
                            {/* UserButton for mobile */}
                            <SignedIn>
                                <div className="mobile-user-button">
                                    <UserButton />
                                </div>
                            </SignedIn>
                        </header>

                        <h1>SportSnipeAI</h1>

                        {isApproved ? (
                            <>
                                <div className="chat-box" ref={chatBoxRef}>
                                    {chatHistory.map((chat, index) => (
                                        <div
                                            key={index}
                                            className="message"
                                            ref={index === chatHistory.length - 1 ? lastMessageRef : null}
                                        >
                                            {chat.user && (
                                                <p className="user">
                                                    <strong>You:</strong> {chat.user}
                                                </p>
                                            )}
                                            {chat.bot && (
                                                <div className="bot">
                                                    <ReactMarkdown>{chat.bot}</ReactMarkdown>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>

                                {isProcessing && (
                                    <div className="spinner">
                                        <FaSpinner className="spinner-icon" />
                                        {spinnerMessage}
                                    </div>
                                )}

                                <div className="input-container">
                                    <textarea
                                        ref={textareaRef}
                                        value={userInput}
                                        onChange={(e) => setUserInput(e.target.value)}
                                        placeholder="Let's find the edge!"
                                        disabled={isProcessing}
                                        rows={1}
                                        onInput={(e) => {
                                            e.target.style.height = 'auto';
                                            e.target.style.height = `${e.target.scrollHeight}px`;
                                        }}
                                    />
                                    <button onClick={handleSend} disabled={isProcessing}>
                                        Send
                                    </button>
                                </div>
                                {/* Footer text */}
                                <div className="footer-text">
                                    Provide the games, sports, or props you are targeting for the best results.
                                </div>
                            </>
                        ) : (
                            <p>Your account is still pending approval. Please check back shortly.</p>
                        )}
                    </div>
                </>
            ) : (
                // Render the LandingPage when signed out
                <LandingPage />
            )}
        </div>
    );
}

export default App;
